<!-- eslint-disable -->
<template>
  <div class="page_box">
    <sb-headline title="美团兑换配置"></sb-headline>
    <my-table ref="list" :data="listData" @page="pageHandler" :listLoading="listLoading">
      <template #header="params">
        <div class="search_box">
          <el-row  style="padding-top: 5px; padding-bottom: 5px">
            <el-col :span="2" style="text-align: end;">
              城市：
            </el-col>
            <el-col :span="3" style="justify-content: left;">
              <el-select v-model="searchData['cityCode']" ref="citySelect">
                <el-option
                  v-for="(item, index) in cityList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
                </el-select>
            </el-col>
            <el-col :span="2" style="text-align: end;">
              门店类型：
            </el-col>
            <el-col :span="3" style="justify-content: left;">
              <el-select v-model="searchData['storeType']">
                <el-option
                  v-for="(item, index) in storeTypeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-col>


            <el-col :span="2" style="text-align: end;">
              兑换时间：
            </el-col>
            <el-col :span="3" style="justify-content: left;">
              <el-date-picker
                v-model="searchData['ctimeRange']"
                type="datetimerange"
                :picker-options="pickerOptions"
                value-format="yyyy-MM-dd HH:mm:ss"
                format="yyyy-MM-dd HH:mm:ss"
                range-separator="至"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
              >
              </el-date-picker>
            </el-col>

            <el-col :span="4" offset="20">
              <el-button @click="resetSearchData">重置</el-button>
              <el-button @click="pageHandler(params, 1)" type="primary">查询</el-button>
            </el-col>
          </el-row>
        </div>
      </template>
      <template #navBar>
        <div class="table_nav_bar">
          <el-button type="primary" @click="openSettingDialog()">设置兑换策略</el-button>
        </div>
      </template>

      <el-table-column prop="storeId" label="门店编号"/>
      <el-table-column prop="storeName" label="门店名称"/>
      <el-table-column prop="count" label="兑换次数"/>
      <el-table-column prop="auth" label="是否授权">
        <template slot-scope="scope">
          <span v-if="scope.row.auth === true">是 </span>
          <span v-if="scope.row.auth === false">否 </span>
        </template>
      </el-table-column>
      <el-table-column prop="default" label="是否默认">
        <template slot-scope="scope">
          <span v-if="scope.row.default === true">是 </span>
          <span v-if="scope.row.default === false">否 </span>
        </template>
      </el-table-column>

      <el-table-column label="操作" fixed="right" prop="" width="250">
        <template slot-scope="scope">
          <el-button type="text" v-if="scope.row.auth === false" @click="handleAuth(scope.row.storeId)">获取授权链接</el-button>
          <el-button type="text" v-if="scope.row.auth === true" @click="handleAuth(scope.row.storeId)">重新授权</el-button>
          <span class="operate-line" v-if="scope.row.auth === true && scope.row.default === false"></span>
          <el-button type="text" v-if="scope.row.auth === true && scope.row.default === false" @click="setDefaultStore(scope.row.storeId, 1)">设为默认</el-button>
          <span class="operate-line" v-if="scope.row.auth === true && scope.row.default === true"></span>
          <el-button type="text" v-if="scope.row.auth === true && scope.row.default === true" @click="setDefaultStore(scope.row.storeId, 2)">取消默认</el-button>
        </template>
      </el-table-column>
    </my-table>


    <el-dialog
      title="设置城市兑换策略"
      :visible.sync="settingDialogVisible"
      :destroy-on-close="true"
      @closed="closedSettingDialog"
      width="70%">

      <el-form ref="settingForm" label-width="80px">
        <el-form-item label="当前城市:">
          <span>{{this.settingForm.cityName}}</span>
        </el-form-item>
        <el-form-item label="核销策略:">
          <el-select v-model="settingForm['strategyCode']" >
            <el-option
              v-for="(item, index) in strategyList"
              :key="item.strategyCode"
              :label="item.strategyName"
              :value="item.strategyCode"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="卫星店:" :hidden="settingForm.strategyCode !== 'fixed'">
          <el-checkbox-group v-model="settingForm.satelliteStoreIds">
            <el-checkbox v-for="(item, index) in fixSatelliteStoreList"
            :key="index"
            :label="item.storeId"
                         :checked = "item.selected"
                         :value="item.storeId">{{item.storeName}}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="工坊店:"  :hidden="settingForm.strategyCode !== 'fixed'">
          <el-checkbox-group v-model="settingForm.workShopStoreIds">
            <el-checkbox v-for="(item, index) in fixWorkShopStoreList"
                         :key="index"
                         :label="item.storeId"
                         :checked = "item.selected"
                         :value="item.storeId">{{item.storeName}}</el-checkbox>

          </el-checkbox-group>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="settingDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="saveCityStrategySetting()">保存</el-button>
      </span>
    </el-dialog>


    <el-dialog
      title="门店授权"
      :visible.sync="authDialogVisible"
      :destroy-on-close="true"
      width="50%">

      <span>{{authDialogLinkUrl}}</span>
      <br>
      <br>

      <el-link type="primary" :href="authDialogLinkUrl">直接跳转</el-link>


      <span slot="footer" class="dialog-footer">
        <el-button @click="authDialogVisible = false" type="primary">关 闭</el-button>
      </span>
    </el-dialog>

  </div>




</template>

<script>
/* eslint-disable */
import { Component, Prop, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { emptyToLine, splitThousands } from "@/utils/common";
import { Table } from "@/utils/decorator";
import QRCode from "qrcodejs2";
import dayjs from "dayjs";
import t from "@common/src/i18n/t";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
    data() {
    //这里存放数据
      return {
        pickerOptions: {
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        },
        storeTypeList: [
          {
            value: "8",
            label: "卫星店"
          },
          {
            value: "3",
            label: "工坊店"
          }
        ],
        cityList: [
          {
            value: "370900",
            label: "泰安"
          },
          {
            value: "340100",
            label: "合肥"
          }
        ],
        strategyList: [],
        listData: [],
        // 搜索数据
        searchData: {
          storeType: "8",
          cityCode: "370900",
          startTime: "",
          endTime: "",
          ctimeRange: []
        },
        listLoading: false ,//列表loading

        settingDialogVisible:false,
        authDialogVisible:false,
        authDialogLinkUrl: "",

        settingForm: {
          cityCode: "",
          cityName: "",
          strategyCode: "",
          satelliteStoreIds: [],
          workShopStoreIds: [],
        },

        fixSatelliteStoreList: [],
        fixWorkShopStoreList: []
      };
    },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    pageHandler(params, num) {
      this.getStoreConfigList(params, num ? num : params.pageNum, params.pageSize);
    },


    handleAuth(storeId){
      this.authDialogLinkUrl = "";
      this.authDialogVisible = true;

      this.$store.dispatch('meituan/getAuthorizationUrl',{ storeId: storeId } ).then(res => {
        if(res.code == 0){
          let url = res.data;
          this.authDialogLinkUrl = url;
        }
      })
    },

    setDefaultStore(storeId, type){
      if (type === 1) {
        this.$confirm('将该门店设为轮询兜底门店, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.listLoading = true;
          this.$store.dispatch('meituan/setDefaultStore',{storeId: storeId}).then(res => {
            if(res.code == 0){
              this.$message({
                message: '操作成功',
                type: 'success'
              });
              this.$refs.list.init();
              this.listLoading = false;
            }
          })
        });
      }
      if (type === 2) {
        this.$confirm('将该门店取消轮询兜底门店, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.listLoading = true;
          this.$store.dispatch('meituan/cancelDefaultStore',{storeId: storeId}).then(res => {
            if(res.code == 0){
              this.$message({
                message: '操作成功',
                type: 'success'
              });
              this.$refs.list.init();
              this.listLoading = false;
            }
          })
        });
      }
    },

    // 重置搜索数据
    resetSearchData(e) {
      this.searchData = {
        storeType: "8",
        cityCode: "370900",
        startTime: "",
        endTime: "",
        ctimeRange: ""
      }
    },

    getStoreConfigList(params, pageNum, pageSize) {
      if (this.searchData.ctimeRange) {
        this.searchData.startTime = this.searchData.ctimeRange[0];
        this.searchData.endTime = this.searchData.ctimeRange[1];
      }

      let paramObj = Object.assign({}, this.searchData)
      paramObj.ctimeRange = "";
      this.listLoading = true;
      this.$store.dispatch('meituan/getStoreConfig',{
        ...paramObj,
        pageNum,
        pageSize
      }).then(res => {
        if(res.code == 0){
          this.listData = res.data.list;
          params.render(res.data.total);
        }
        this.listLoading = false;
      })
    },

    openSettingDialog(){
      this.settingDialogVisible = true
      this.settingForm.cityCode = this.searchData.cityCode

      const selectedItem = this.$refs.citySelect.options.find(item => item.value === this.searchData.cityCode);

      this.settingForm.cityName = selectedItem.label
      this.getCityStrategy();
    },

    closedSettingDialog(){
      this.settingForm.cityCode = "";
      this.settingForm.strategyCode = "";
      this.settingForm.satelliteStoreIds = [];
      this.settingForm.workShopStoreIds = [];
    },
    getAllStrategy(){
      this.$store.dispatch('meituan/getAllStrategy',{ } ).then(res => {
        if(res.code == 0){
          this.strategyList = res.data;
        }
      })
    },
    getCityStrategy(){
      this.$store.dispatch('meituan/getCityStrategy',{cityCode:this.settingForm.cityCode } ).then(res => {
        if(res.code == 0){
          this.settingForm.strategyCode = res.data.strategyCode;
          this.fixSatelliteStoreList = res.data.satelliteStores;
          this.fixWorkShopStoreList = res.data.workShopStores;
        }
      })
    },

    saveCityStrategySetting() {
      console.log(this.settingForm);
      this.$store.dispatch('meituan/setCityStrategy',{...this.settingForm} ).then(res => {
        if(res.code == 0){
          this.$message({
            message: '保存成功',
            type: 'success'
          });
          this.settingDialogVisible = false;
        }
      })
    }


  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getAllStrategy();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {

  },
  //生命周期 - 创建之前
  beforeCreate() {},
  //生命周期 - 挂载之前
  beforeMount() {},
  //生命周期 - 更新之前
  beforeUpdate() {},
  //生命周期 - 更新之后
  updated() {},
  //生命周期 - 销毁之前
  beforeDestroy() {},
  //生命周期 - 销毁完成
  destroyed() {},
  //如果页面有keep-alive缓存功能，这个函数会触发
  activated() {},
}
</script>
<style lang="scss" scoped>
//@import url(); 引入公共css类
.page_box {
  padding: 0 20px;
  .search_box {
    background: #fff;
    padding: 20px;
    margin-top: 20px;
  }
  .table_nav_bar {
    width: 100%;
    background: #fff;
    padding: 20px 20px 0 20px;
    box-sizing: border-box;
  }
}
.handle_btn {
  padding: 0;
}
.channel_item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  > .el-input:nth-child(1) {
    width: 260px;
  }
}
.channel_item:last-child {
  margin: 0;
}
.popularize_list_qrcode {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.qrcode_img_src {
  position: absolute;
  top: -10000%;
  z-index: -10000;
}
::v-deep .el-table__header thead tr th:first-of-type,
.el-table__body tbody tr td:first-of-type {
  padding: 0 !important;
}
::v-deep .el-table__body tbody tr td:first-of-type {
  padding-left: 0px !important;
}
</style>
